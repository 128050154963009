import React from 'react';
import { Checkbox, Radio } from 'react-daisyui';

type ItemConfig = {
  label: string;
  value: string;
  description: string;
};

type Props<T> = {
  value: T;
  name: string;
  onChange: (value: T) => void;
  className?: string;
  items: Array<ItemConfig>;
};

type RadioChecklistProps = Props<string>;

const CheckboxChecklistItem: React.FC<{
  parentProps: Props<Array<string>>;
  item: ItemConfig;
}> = ({ parentProps, item }) => {
  const isActive = parentProps.value.includes(item.value);

  const onClickCb = () => {
    if (isActive) {
      parentProps.onChange(
        parentProps.value.filter((value) => value !== item.value)
      );
    } else {
      parentProps.onChange([...parentProps.value, item.value]);
    }
  };

  return (
    <div className="bg-base-100 mb-6">
      <label
        className={`flex cursor-pointer border-2 rounded-lg p-4 ${
          isActive ? 'border-blue-400' : ''
        }`}
      >
        <div className="flex-grow">
          <div className="font-bold mb-2">{item.label}</div>
          <div className="text-sm">{item.description}</div>
        </div>
        <Checkbox
          name={parentProps.name}
          color={isActive ? 'primary' : undefined}
          value={item.value}
          checked={isActive}
          onChange={onClickCb}
        />{' '}
      </label>
    </div>
  );
};

const RadioChecklistItem: React.FC<{
  parentProps: Props<string>;
  item: ItemConfig;
}> = ({ parentProps, item }) => {
  const isActive = parentProps.value === item.value;

  const onClickCb = () => {
    if (isActive) {
      parentProps.onChange('');
    } else {
      parentProps.onChange(item.value);
    }
  };

  return (
    <div className="bg-base-100 mb-6">
      <label
        className={`flex cursor-pointer border-2 rounded-lg p-4 ${
          isActive ? 'border-blue-400' : ''
        }`}
      >
        <div className="flex-grow">
          <div className="font-bold mb-2">{item.label}</div>
          <div className="text-sm">{item.description}</div>
        </div>
        <Radio
          name={parentProps.name}
          value={item.value}
          className="checked:bg-blue-500"
          checked={isActive}
          onChange={onClickCb}
        />
      </label>
    </div>
  );
};

const CheckboxChecklist: React.FC<Props<Array<string>>> = (props) => {
  return (
    <div className={props.className}>
      {props.items.map((item) => (
        <CheckboxChecklistItem
          parentProps={props}
          item={item}
          key={item.value}
        />
      ))}
    </div>
  );
};

const RadioChecklist: React.FC<RadioChecklistProps> = (props) => {
  return (
    <div className={props.className}>
      {props.items.map((item) => (
        <RadioChecklistItem parentProps={props} item={item} key={item.value} />
      ))}
    </div>
  );
};

export { CheckboxChecklist, RadioChecklist };
