import { useEffect, useState } from 'react';
import { Alert, Button, Card } from 'react-daisyui';
import AddSiteDrawer from '../Components/PageComponents/AddSiteDrawer';
import PageLayout from '../Components/PageLayout';
import { useAuth } from '../Contexts/AuthContext';
import { supabase } from '../Utils/supabaseClient';
import { Database } from '../Types/supabase';
import WebsiteStatus, { statuses } from '../Components/WebsiteStatus';
import Icon from '../Components/Icon';
import Spinner from '../Components/Spinner';
import RunScanDrawer from '../Components/PageComponents/RunScanDrawer';
import { randomInt } from 'crypto';
import EditSiteDrawer from '../Components/PageComponents/EditSiteDrawer';

const statusIdToString = (
  status: Number
): 'indeterminate' | 'success' | 'error' => {
  switch (status) {
    case 0:
      return 'indeterminate';
    case 1:
      return 'success';
    case 2:
    default:
      return 'error';
  }
};

export type ScanTypes = 'home_page' | 'critical_pages' | 'full_scan';
export type EnhancedWebsiteRow =
  Database['public']['Tables']['websites']['Row'] & {
    thumbnailUrl: string;
    runningScan: boolean;
  };
type InProgressScan = {
  scanId: string;
  websiteId: string;
};

export default function HomePage() {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [createDrawerOpen, setCreateDrawerOpen] = useState<boolean>(false);
  const [runScanDrawerOpen, setRunScanDrawerOpen] = useState<boolean>(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const [websites, setWebsites] = useState<Array<EnhancedWebsiteRow>>([]);
  const [reloadData, setReloadData] = useState<number>(0);
  const [websiteTarget, setWebsiteTarget] =
    useState<EnhancedWebsiteRow | null>();
  const [inProgressScans, setInProgressScans] = useState<InProgressScan[]>([]);

  const onCreatedSite = () => {
    setCreateDrawerOpen(false);
    refreshData();
  };
  const refreshData = () => {
    // Triggers fresh websites data call
    setReloadData(reloadData + 1);
  };

  const toggleCreateDrawer = () => {
    setCreateDrawerOpen(!createDrawerOpen);
  };

  const toggleRunScanDrawer = () => {
    if (runScanDrawerOpen) {
      setWebsiteTarget(null);
    }
    setRunScanDrawerOpen(!runScanDrawerOpen);
  };

  const toggleEditDrawer = () => {
    if (editDrawerOpen) {
      setWebsiteTarget(null);
    }
    setEditDrawerOpen(!editDrawerOpen);
  };

  const startScanCallback = (scanId: string, websiteId: string) => {
    // Update the website row to show that it's running a scan
    const newWebsites = websites.map((website) => {
      if (website.id === websiteId) {
        return { ...website, runningScan: true };
      }
      return website;
    });
    setWebsites(newWebsites);

    // Add the scan to the inProgressScans array
    setInProgressScans([...inProgressScans, { scanId, websiteId }]);
  };

  const removeWebsite = async (websiteId: string) => {
    await supabase.from('websites').delete().eq('id', websiteId);
    refreshData();
  };

  useEffect(() => {
    setLoading(true);
    supabase
      .from('websites')
      .select()
      .order('name', { ascending: true })
      .then(({ data, error, status }) => {
        if (!data) {
          setLoading(false);
          return;
        }
        const websites: Array<EnhancedWebsiteRow> = [];
        for (const row of data) {
          websites.push({
            ...row,
            thumbnailUrl: '/screenshot.png',
            runningScan: inProgressScans.some(
              (scan) => scan.websiteId === row.id
            ),
          });
        }
        setWebsites(websites);
        setLoading(false);
        const promises = [];
        for (const row of data) {
          promises.push(
            supabase.storage
              .from('scanning-data')
              .createSignedUrl(`${row.id}/index/screenshot-thumbnail.png`, 60)
          );
        }

        Promise.all(promises).then((results) => {
          // update the make a copy of the websites object and overwrite the thumbnailUrl to what comes back in the promise results
          let newWebsites: Array<EnhancedWebsiteRow> = [...websites];
          results.forEach((result, index) => {
            newWebsites[index].thumbnailUrl =
              result.data?.signedUrl || '/screenshot.png';

            newWebsites[index].runningScan = inProgressScans.some(
              (scan) => scan.websiteId === newWebsites[index].id
            );
          });
          setWebsites(newWebsites);
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  useEffect(() => {
    console.log('inProgressScans');
    if (inProgressScans.length === 0) {
      console.log('exiting coz nothing to do');
      return;
    }
    setTimeout(() => {
      console.log('starting ', inProgressScans);
      const promises = [];
      if (inProgressScans.length === 0) {
        return;
      }
      for (const { scanId } of inProgressScans) {
        promises.push(
          supabase
            .from('scans')
            .select('id, website_id, completed_at')
            .eq('id', scanId)
            .single()
        );
      }
      Promise.all(promises).then((results) => {
        let shouldRefreshData = false;
        const newInProgressScans: Array<InProgressScan> = [];
        for (const result of results) {
          if (!result.data || !result.data?.website_id) {
            console.log('no data came back from scan');
            shouldRefreshData = true;
            continue;
          }
          console.log('result', result);
          if (result.data?.completed_at) {
            console.log('scan completed');
            shouldRefreshData = true;
          } else {
            console.log('scan still going');
            newInProgressScans.push({
              scanId: result.data.id,
              websiteId: result.data.website_id,
            });
          }
        }
        setInProgressScans(newInProgressScans);

        if (shouldRefreshData) {
          // set the reload data to a random number to trigger a refresh
          setReloadData(Math.random() * 1000);
        }
      });
    }, 6000);
  }, [inProgressScans]);

  let content = null;
  if (websites.length === 0) {
    content = (
      <Alert
        className="rounded rounded-lg border-4 border-slate-200	border-dashed flex"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="w-6 h-6 mx-2 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        }
      >
        <div className="flex-1">No sites configured yet!</div>
        <Button color="primary" onClick={toggleCreateDrawer}>
          Get started
        </Button>
      </Alert>
    );
  } else {
    content = (
      <div>
        {websites.map((website) => {
          const isNewSite =
            website.uptime_status === 0 &&
            website.link_status === 0 &&
            website.image_status === 0 &&
            website.error_status === 0 &&
            website.lead_status === 0;
          const statuses: statuses = [
            { type: statusIdToString(website.uptime_status), label: 'Uptime' },
            { type: statusIdToString(website.link_status), label: 'Links' },
            { type: statusIdToString(website.image_status), label: 'Images' },
            { type: statusIdToString(website.error_status), label: 'Errors' },
            { type: statusIdToString(website.lead_status), label: 'Leads' },
            {
              type: statusIdToString(website.schedule_status),
              label: 'Schedule',
            },
          ];
          return (
            <Card
              key={website.id}
              className="card-side bg-base-100 overflow-hidden mb-10"
            >
              <Card.Image
                src={website.thumbnailUrl}
                alt="Shoes"
                className="object-cover object-top ml-8 w-48 h-60 shadow-lg"
              />

              <Card.Body>
                <Card.Title tag="h2">{website.name}</Card.Title>
                <div>
                  <div className="mb-4">
                    <a
                      href={website.url}
                      className="font-semibold text-sm text-slate-500 hover:underline"
                    >
                      {website.url}
                    </a>
                  </div>
                  <hr />
                  <WebsiteStatus statuses={statuses} />
                </div>
                <Card.Actions className="mt-4">
                  <p className="flex-grow mt-3 text-xs italic text-slate-400 max-md:hidden">
                    {/* Last checked 10 minutes ago */}
                  </p>

                  {isNewSite ? (
                    <div>
                      {!website.runningScan ? (
                        <Button
                          color="ghost"
                          className="mr-2"
                          onClick={() => {
                            removeWebsite(website.id);
                          }}
                        >
                          Remove
                        </Button>
                      ) : null}
                      <Button
                        color="primary"
                        variant="outline"
                        onClick={() => {
                          setWebsiteTarget(website);
                          toggleRunScanDrawer();
                        }}
                        loading={website.runningScan}
                        disabled={website.runningScan}
                      >
                        Run First Scan
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        color="ghost"
                        className="mr-2"
                        disabled={website.runningScan}
                        loading={website.runningScan}
                        onClick={() => {
                          setWebsiteTarget(website);
                          toggleRunScanDrawer();
                        }}
                      >
                        Run Scan
                      </Button>
                      {!website.runningScan ? (
                        <Button
                          color="primary"
                          variant="outline"
                          onClick={() => {
                            setWebsiteTarget(website);
                            toggleEditDrawer();
                          }}
                        >
                          Configure
                        </Button>
                      ) : null}
                    </div>
                  )}
                </Card.Actions>
              </Card.Body>
            </Card>
          );
        })}

        <Alert
          className="rounded rounded-lg border-4 border-slate-200	border-dashed flex"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="w-6 h-6 mx-2 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          }
        >
          <div className="flex-1">Add another site</div>
          <Button color="ghost" onClick={toggleCreateDrawer}>
            Add Site
          </Button>
        </Alert>
      </div>
    );
  }

  return (
    <PageLayout heading="Sites" loading={loading}>
      {content}
      <AddSiteDrawer
        visible={createDrawerOpen}
        toggleVisible={toggleCreateDrawer}
        auth={auth}
        onCompleted={onCreatedSite}
      />
      <RunScanDrawer
        visible={runScanDrawerOpen}
        toggleVisible={toggleRunScanDrawer}
        onCompleted={startScanCallback}
        website={websiteTarget}
      />
      <EditSiteDrawer
        visible={editDrawerOpen}
        toggleVisible={toggleEditDrawer}
        onCompleted={() => {
          refreshData();
        }}
        website={websiteTarget}
      />
    </PageLayout>
  );
}
