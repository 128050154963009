import { FormEvent, useState, ReactNode } from 'react';
import { Button, Input, Modal, Select } from 'react-daisyui';
import { AuthContextType } from '../../Contexts/AuthContext';
import { supabase } from '../../Utils/supabaseClient';
import {
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from '@supabase/supabase-js';
import * as uuid from 'uuid';
import Drawer from '../Drawer';
import DrawFormSection from '../DrawerFormSection';
import FormLabel from '../FormLabel';

type props = {
  visible: boolean;
  toggleVisible: () => void;
  onCompleted: () => void;
  auth: AuthContextType;
};

export default function AddSiteDrawer({
  visible,
  toggleVisible,
  auth,
  onCompleted,
}: props) {
  const [siteUrl, setSiteUrl] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');
  const [teamName, setTeamName] = useState<string | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    let teamIdOverride = teamId;
    if (auth.teams.length > 0) {
      teamIdOverride = auth.teams[0].id;
    }

    if (!teamId) {
      teamIdOverride = uuid.v4();
      await supabase.from('teams').insert({
        id: teamIdOverride,
        team_name: teamName,
        created_by_user_id: auth.session.user.id,
      });
      await supabase.from('team_members').insert({
        id: uuid.v4(),
        team_id: teamIdOverride,
        user_id: auth.session.user.id,
        is_owner: true,
        is_read_only: false,
      });
    }

    // try {
    const { data, error } = await supabase.functions.invoke('create_site', {
      body: { teamId: teamIdOverride, siteUrl, name: siteName },
      method: 'POST',
    });

    if (error instanceof FunctionsHttpError) {
      setLoading(false);
      setHasErrors(true);
    } else if (error instanceof FunctionsRelayError) {
      setLoading(false);
    } else if (error instanceof FunctionsFetchError) {
      setLoading(false);
    } else {
      console.log(data);
      setTimeout(() => {
        setLoading(false);
        onCompleted();
      }, 200);
    }
  };
  const handleCancel = (e: FormEvent) => {
    e.preventDefault();
    toggleVisible();
  };

  const footer = (
    <div className="flex justify-between">
      <Button color="ghost" onClick={handleCancel}>
        Cancel
      </Button>
      <Button color="primary" disabled={loading} loading={loading}>
        Add
      </Button>
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Drawer
        title="Add Site"
        visible={visible}
        toggleVisible={toggleVisible}
        footer={footer}
      >
        <DrawFormSection title="Details">
          <FormLabel
            label="Website URL"
            error={
              hasErrors ? 'Website must be active and begin with https://' : ''
            }
          >
            <Input
              placeholder="https://example.happysiteapp.com"
              type="url"
              className="w-full"
              required
              onChange={(e) => {
                setSiteUrl(e.target.value);
              }}
              color={hasErrors ? 'error' : undefined}
            />
          </FormLabel>
          <FormLabel label="Website Name">
            <Input
              className="w-full"
              required
              onChange={(e) => {
                setSiteName(e.target.value);
              }}
            />
          </FormLabel>
        </DrawFormSection>
        <DrawFormSection title="Team">
          <FormLabel label="Team Name">
            {auth.teams.length > 0 && (
              <Select
                defaultValue={auth.teams[0].id}
                onChange={console.log}
                className="w-full"
              >
                {auth.teams.map((team) => (
                  <Select.Option key={team.id} value={team.id}>
                    {team.team_name}
                  </Select.Option>
                ))}
              </Select>
            )}
            {auth.teams.length === 0 && (
              <Input
                className="w-full"
                required
                onChange={(e) => {
                  setTeamName(e.target.value);
                }}
              />
            )}
          </FormLabel>
        </DrawFormSection>
      </Drawer>
    </form>
  );
}
