import { Navigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import { supabase } from '../Utils/supabaseClient';

export default function LogoutPage() {
  const auth = useAuth();

  if (!auth.session) {
    return <Navigate to="/login" />;
  }

  supabase.auth.signOut();

  return <Navigate to="/login" />;
}
