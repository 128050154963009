import { Alert } from 'react-daisyui';

export default function LoaderAlert() {
  return (
    <Alert className="h-24 border-2 rounded-md">
      <div className="flex flex-col space-y-3">
        <div className="w-36 bg-gray-300 h-6 rounded-md "></div>
        <div className="w-24 bg-gray-300 h-6 rounded-md "></div>
      </div>
    </Alert>
  );
}
