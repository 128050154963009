import './index.css';
import * as React from 'react';
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { AuthProvider, useAuth } from '../Contexts/AuthContext';
import { LoginPage } from '../Pages/LoginPage';
import HomePage from '../Pages/HomePage';
import NotFound from '../Pages/NotFoundPage';
import LogoutPage from '../Pages/LogoutPage';

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route
            path="/home"
            element={
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            }
          />
          <Route
            path="/teams"
            element={
              <RequireAuth>
                <DummyPage />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <DummyPage />
              </RequireAuth>
            }
          />
          <Route
            path="*"
            element={
              <RequireAuth>
                <NotFound />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  let auth = useAuth();
  return (
    <div>
      <Navbar session={auth.session} />
      <Outlet />
    </div>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.isLoading) {
    return <div></div>;
  }

  if (!auth.session) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function DummyPage() {
  const location = useLocation();
  return <div>{location.pathname}</div>;
}
