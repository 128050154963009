import React from 'react';
import { Button } from 'react-daisyui';
import Icon from './Icon';

type props = {
  visible: boolean;
  toggleVisible: () => void;
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
};

const Drawer = ({ visible, toggleVisible, title, children, footer }: props) => {
  let footerRender = null;
  if (footer) {
    footerRender = (
      <div className="sticky bottom-0 bg-base-200 px-4 py-2">
        <hr className="border-t" />
        <div className="py-2">{footer}</div>
      </div>
    );
  }

  return (
    <div>
      {/* Drawer Overlay */}
      {visible && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={toggleVisible}
        />
      )}

      {/* Drawer Content */}
      <div
        className={`fixed top-0 right-0 w-4/5 max-w-2xl bg-base-200 h-screen transform transition-transform ${
          visible ? 'translate-x-0' : 'translate-x-full'
        } z-50`}
      >
        <div>
          <div className="sticky top-0 p-4 flex justify-between">
            <h1 className="text-xl font-bold">{title}</h1>
            <Button color="ghost" onClick={toggleVisible} size="xs">
              <Icon name="close-outline" className="text-xl" />
            </Button>
          </div>
          <hr className="border-t" />

          <div className="overflow-y-auto h-screen">
            <div className="px-4 py-2">{children}</div>
          </div>

          {footerRender}
        </div>
      </div>
    </div>
  );
};

export default Drawer;
