import { FormEvent, useEffect, useState } from 'react';
import { Button, Input } from 'react-daisyui';
import { EnhancedWebsiteRow } from '../../Pages/HomePage';
import Drawer from '../Drawer';
import DrawFormSection from '../DrawerFormSection';
import FormLabel from '../FormLabel';
import { CheckboxChecklist } from '../Checklist';

type props = {
  visible: boolean;
  toggleVisible: () => void;
  onCompleted: () => void;
  website?: EnhancedWebsiteRow | null;
};

export default function Edit({
  visible,
  toggleVisible,
  onCompleted,
  website,
}: props) {
  const [siteUrl, setSiteUrl] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [scanSchedule, setScanSchedule] = useState<Array<string>>([]);

  useEffect(() => {
    if (!visible || !website) {
      return;
    }
    console.log(website);
    setSiteUrl(website.url || '');
    setSiteName(website.name || '');
  }, [visible, website]);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      toggleVisible();
      onCompleted();
    }, 200);
  };
  const handleCancel = (e: FormEvent) => {
    e.preventDefault();
    toggleVisible();
  };

  const footer = (
    <div className="flex justify-between">
      <Button color="ghost" onClick={handleCancel}>
        Cancel
      </Button>
      <Button color="primary" disabled={loading} loading={loading}>
        Save
      </Button>
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Drawer
        title={`Edit ${website?.name}`}
        visible={visible}
        toggleVisible={toggleVisible}
        footer={footer}
      >
        <DrawFormSection title="Details">
          <FormLabel
            label="Website URL"
            error={
              hasErrors ? 'Website must be active and begin with https://' : ''
            }
          >
            <Input
              placeholder="https://example.happysiteapp.com"
              value={siteUrl}
              type="url"
              className="w-full"
              required
              onChange={(e) => {
                setSiteUrl(e.target.value);
              }}
              color={hasErrors ? 'error' : undefined}
            />
          </FormLabel>
          <FormLabel label="Website Name">
            <Input
              className="w-full"
              required
              value={siteName}
              onChange={(e) => {
                setSiteName(e.target.value);
              }}
            />
          </FormLabel>
        </DrawFormSection>
        <DrawFormSection title="Scan Schedule">
          <CheckboxChecklist
            name="scan_schedule"
            value={scanSchedule}
            onChange={setScanSchedule}
            items={[
              {
                label: 'Uptime Monitoring',
                value: 'daily',
                description:
                  "Pings your website every few minutes to check it's up",
              },
              {
                label: 'Critical Pages',
                value: 'weekly',
                description:
                  "Scans your most important pages every hour to ensure they're looking their best",
              },
              {
                label: 'Full Site Scan',
                value: 'monthly',
                description:
                  'Run a full site scan every month to check for broken links, images, and page errors',
              },
            ]}
          />
        </DrawFormSection>
        <DrawFormSection title="Remove Site">
          <Button
            color="error"
            variant="outline"
            disabled={loading}
            loading={loading}
          >
            Remove Permenantly
          </Button>
        </DrawFormSection>
      </Drawer>
    </form>
  );
}
