import React from 'react';

type props = {
  title: string;
  description?: string;
  children: React.ReactNode;
};

const DrawerFormSection = ({ title, description, children }: props) => (
  <div className="mb-6 mt-6">
    <div className="grid grid-cols-4 gap-4">
      <div>
        <h3 className="font-bold text-sm">{title}</h3>
        {description ? (
          <label className="label px-0">
            <span className="label-text">{description}</span>
          </label>
        ) : null}
      </div>
      <div className="col-span-3">{children}</div>
    </div>
    <hr className="border-t mt-6" />
  </div>
);

export default DrawerFormSection;
