import { Session } from '@supabase/supabase-js';
import { Navbar, Dropdown, Button } from 'react-daisyui';
import md5 from 'md5';
import { Link, useLocation } from 'react-router-dom';

type navbarArgs = {
  session?: Session;
};

export default function HsNavbar({ session }: navbarArgs) {
  const location = useLocation();
  if (!session || typeof session === 'undefined' || !session.user.email) {
    return null;
  }
  const avatarUrl = `https://www.gravatar.com/avatar/${md5(
    session.user.email.toLowerCase()
  )}`;
  const underlinedClasses =
    'underline underline-offset-4 decoration-2 decoration-solid';
  return (
    <div className="pb-4 gap-2">
      <Navbar className="bg-base-100">
        <div className="flex-1">
          <Link to="/home">
            <Button color="ghost" className="text-xl">
              Happy Site
            </Button>
          </Link>
          <Link to="/home">
            <Button
              color="ghost"
              className={location.pathname === '/home' ? underlinedClasses : ''}
            >
              Results
            </Button>
          </Link>
          <Link to="/teams">
            <Button
              color="ghost"
              className={
                location.pathname === '/teams' ? underlinedClasses : ''
              }
            >
              Teams
            </Button>
          </Link>
        </div>
        <div className="flex-none">
          <Dropdown vertical="bottom" end={true}>
            <Button color="ghost" className="avatar" shape="circle">
              <div className="w-10 rounded-full">
                <img src={avatarUrl} alt="Profile" />
              </div>
            </Button>
            <Dropdown.Menu className="w-36 menu-compact">
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/logout">Logout</Link>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
    </div>
  );
}
