import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import { supabase } from '../Utils/supabaseClient';

// TODO work this out
// type errorType = {
//   error_description?: string;
//   message?: string;
// };

export function LoginPage() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    // @ts-ignore
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo:
            process.env.REACT_APP_SIGNIN_REDIRECT_TO ??
            'https://my.happysiteapp.com/',
        },
      });
      if (error?.message) {
        setError(error?.message);
      } else {
        setSuccess('Check your email for the login link!');
      }
    } catch (error: unknown) {
      // @ts-ignore
      if (error?.message) {
        // @ts-ignore
        setError(error?.message);
      }
      // @ts-ignore
      if (error?.error_description) {
        // @ts-ignore
        setError(error?.error_description);
      }
    } finally {
      setLoading(false);
    }
  };

  if (auth.session && !auth.isLoading) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row">
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold w-72">Login now!</h1>
          <p className="py-6">Sign in via magic link with your email below</p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
          <form onSubmit={handleLogin}>
            <div className="card-body">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="email"
                  placeholder="Your email"
                  className="input input-bordered"
                  required
                  disabled={loading}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {error.length > 0 && (
                <div className="alert alert-error shadow-lg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>{error}</span>
                  </div>
                </div>
              )}
              {success.length > 0 && (
                <div className="alert alert-success shadow-lg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>{success}</span>
                  </div>
                </div>
              )}
              <div className="form-control mt-6">
                <button disabled={loading} className="btn btn-primary">
                  Send magic link
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
