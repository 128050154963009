import { Session } from '@supabase/supabase-js';
import * as React from 'react';
import { Database } from '../Types/supabase';
import { supabase } from '../Utils/supabaseClient';

export interface AuthContextType {
  session: Session;
  teams: Array<Database['public']['Tables']['teams']['Row']>;
  isLoading: boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [session, setSession] = React.useState<any>(null);
  let [teams, setTeams] = React.useState<
    Array<Database['public']['Tables']['teams']['Row']>
  >([]);
  let [isLoading, setLoading] = React.useState<boolean>(true);
  let [isLoadingTeams, setLoadingTeams] = React.useState<boolean>(false);

  const refreshTeams = () => {
    setLoadingTeams(true);
    supabase
      .from('teams')
      .select()
      .then(({ data, error, status }) => {
        if (data) {
          setTeams(data);
        }
        setLoadingTeams(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (isLoading && !isLoadingTeams) {
      setLoadingTeams(true);
      supabase.auth
        .getSession()
        .then(({ data: { session } }) => {
          setSession(session);
          refreshTeams();
        })
        .finally(() => {
          setLoading(false);
        });
    }
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (isLoading && !isLoadingTeams) {
        refreshTeams();
      }
    });
  }, [isLoading, isLoadingTeams]);

  let value = { session, isLoading, teams, refreshTeams };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
