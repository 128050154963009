import { createClient, Session } from '@supabase/supabase-js';
import { Database } from '../Types/supabase';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (typeof supabaseUrl === 'undefined' || typeof supabaseAnonKey === 'undefined') {
  throw new Error('Missing env settings');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);
