import Icon from './Icon';

type status = {
  type: 'success' | 'error' | 'indeterminate';
  label: string;
};

export type statuses = Array<status>;

type props = {
  statuses: statuses;
};

export default function WebsiteStatus({ statuses }: props) {
  return (
    <div className="grid grid-cols-3 gap-2 md:grid-cols-6 my-4 mx-4">
      {statuses.map(({ type, label }) => {
        let icon = '';
        let iconClasses = 'text-4xl pt-1 ';
        switch (type) {
          case 'success':
            icon = 'checkmark-circle-outline';
            iconClasses += 'text-green-400';
            break;
          case 'error':
            icon = 'close-circle-outline';
            iconClasses += 'text-red-400';
            break;
          default:
            icon = 'remove-circle-outline';
            iconClasses += 'text-slate-400';
            break;
        }
        return (
          <div key={label} className="text-center">
            <Icon name={icon} className={iconClasses} />
            <br />
            <p className="text-xs font-semibold	text-slate-500">{label}</p>
          </div>
        );
      })}
    </div>
  );
}
