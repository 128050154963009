import LoaderAlert from "./LoaderAlert";

type props = {
  heading: string;
  children: React.ReactNode;
  loading: boolean;
};

export default function PageLayout({ heading, loading, children }: props) {
  if (loading) {
    return (
      <div className="max-w-screen-lg mx-auto p-4">
      <h1 className="text-2xl font-bold	mb-4">{heading}</h1>
      <LoaderAlert />
    </div>
    );
  }
  return (
    <div className="max-w-screen-lg mx-auto p-4">
      <h1 className="text-2xl font-bold	mb-4">{heading}</h1>
      {children}
    </div>
  );
}
