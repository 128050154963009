import React from 'react';

interface MyComponentProps {
  name: string;
  className?: string;
}

const Icon: React.FC<MyComponentProps> = ({ name, className }) => {
  const innerHtml = `<ion-icon name="${name}"></ion-icon>`;
  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: innerHtml }}
    ></span>
  );
};

export default Icon;
