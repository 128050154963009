import React from 'react';

interface MyComponentProps {
  label: string;
  children: React.ReactNode;
  className?: string;
  error?: string;
  description?: string;
}

const FormLabel: React.FC<MyComponentProps> = ({
  label,
  children,
  className,
  description,
  error,
}) => (
  <div className={`form-control ${className} mb-6`}>
    <label>
      <div className="text-sm mb-2">{label}</div>
      {children}
    </label>
    {error ? (
      <div className="text-sm my-2 text-error font-bold">{error}</div>
    ) : null}
    {!error && description ? (
      <div className="text-sm my-2">{description}</div>
    ) : null}
  </div>
);

export default FormLabel;
