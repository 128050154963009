import React from 'react';
import ReactDOM from 'react-dom/client';
import './Bootstrap/index.css';
import App from './Bootstrap/App';
import reportWebVitals from './Utils/reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
const inner = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

if (process.env.REACT_APP_BUGSNAG_TOKEN) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_TOKEN,
    plugins: [new BugsnagPluginReact()],
  });

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  root.render(<ErrorBoundary>{inner}</ErrorBoundary>);
} else {
  root.render(inner);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
