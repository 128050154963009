import { FormEvent, useState } from 'react';
import { Button } from 'react-daisyui';
import Drawer from '../Drawer';
import DrawFormSection from '../DrawerFormSection';
import { EnhancedWebsiteRow, ScanTypes } from '../../Pages/HomePage';
import { supabase } from '../../Utils/supabaseClient';
import { RadioChecklist } from '../Checklist';

type props = {
  visible: boolean;
  toggleVisible: () => void;
  onCompleted: (scanId: string, websiteId: string) => void;
  website?: EnhancedWebsiteRow | null;
};

export default function RunScanDrawer({
  visible,
  toggleVisible,
  website,
  onCompleted,
}: props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [scanType, setScanType] = useState<ScanTypes>('home_page');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!website) return;
    setLoading(true);

    const result = await supabase.functions.invoke('initiate_scan', {
      body: { websiteId: website.id, scanType },
      method: 'POST',
    });
    console.log(result);

    setTimeout(() => {
      setLoading(false);
      toggleVisible();
      onCompleted(result.data.scanId, website.id);
    }, 200);
  };
  const handleCancel = (e: FormEvent) => {
    e.preventDefault();
    toggleVisible();
  };

  const footer = (
    <div className="flex justify-between">
      <Button color="ghost" onClick={handleCancel}>
        Cancel
      </Button>
      <Button color="primary" disabled={loading} loading={loading}>
        Start Scan
      </Button>
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Drawer
        title={`Run Scan for ${website?.name}`}
        visible={visible}
        toggleVisible={toggleVisible}
        footer={footer}
      >
        <DrawFormSection title="Scan Type">
          <div>
            <RadioChecklist
              name="scanType"
              value={scanType}
              onChange={(value) => {
                setScanType(value as ScanTypes);
              }}
              items={[
                {
                  label: 'Home Page',
                  value: 'home_page',
                  description: 'Scan the home page only',
                },
                {
                  label: 'Critical Pages',
                  value: 'critical_pages',
                  description: 'Scans the most important pages on the website',
                },
                {
                  label: 'Full Scan',
                  value: 'full_scan',
                  description: 'Scan all the pages on the website',
                },
              ]}
            />
          </div>
        </DrawFormSection>
      </Drawer>
    </form>
  );
}
